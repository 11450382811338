<template>
  <div class="block-left">
    <label class="avatar">
      <img v-if="profile.avatar" :src="profile.avatar" />
      <span v-else>{{ firstNameSymbol(profile) }}</span>
      <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
      />
    </label>
    <div v-if="imgSrc" class="upload-avatar-popup shop-popup">
      <div class="content">
        <img
          @click="imgSrc = ''"
          src="@/assets/images/shop-close.svg"
          class="close"
        />
        <div class="img-cropper">
          <vue-cropper ref="cropper" :aspect-ratio="1 / 1" :src="imgSrc" />
        </div>
        <button @click.prevent="cropImage">Сохранить</button>
      </div>
    </div>
    <span class="user-name">
      {{ profile.firstName }} {{ profile.lastName }}
    </span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_settings' })"
        :class="{ active: hrefTo === 'settings' }"
      >
        Настройка профиля
      </a>
    </span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_loyalty_program' })"
        :class="{ active: hrefTo === 'loyalty_program' }"
      >
        Программа лояльности
      </a>
    </span>
    <span class="header">Покупки</span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_orders' })"
        :class="{ active: hrefTo === 'orders' }"
      >
        Мои заказы
      </a>
    </span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_products' })"
        :class="{ active: hrefTo === 'products' }"
      >
        Мои товары
      </a>
    </span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_returns' })"
        :class="{ active: hrefTo === 'returns' }"
      >
        Мои возвраты и замены
      </a>
    </span>
    <!--    <span class="header">Ассистент</span>-->
    <!--    <span class="href-to">-->
    <!--      <a-->
    <!--        @click="$router.push({ name: 'personal_questionnaire' })"-->
    <!--        :class="{ active: hrefTo === 'questionnaire' }"-->
    <!--      >-->
    <!--        Подбор витаминов-->
    <!--      </a>-->
    <!--    </span>-->
    <span class="header">Уведомления</span>
    <span class="href-to">
      <a
        @click="$router.push({ name: 'personal_subscribers' })"
        :class="{ active: hrefTo === 'subscribers' }"
      >
        Подписки
      </a>
    </span>
    <span class="header" v-if="isPartner">PRO-аккаунт</span>
    <span class="href-to" v-if="isPartner">
      <a
        @click="$router.push({ name: 'personal_partner' })"
        :class="{ active: hrefTo === 'partner' }"
      >
        Партнерская программа
      </a>
    </span>
    <span class="href-to">
      <a @click="$router.push({ name: 'logout' })">Выйти из профиля</a>
    </span>
  </div>
</template>

<script>
import profileMixin from "@/mixins/profileMixin";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import api from "@/api";

export default {
  mixins: [profileMixin],
  components: {
    VueCropper,
  },
  props: {
    hrefTo: String,
  },
  data() {
    return {
      imgSrc: "",
      isPartner: false,
    };
  },
  async created() {
    try {
      const result = await api.profile.getPartnerBalance();
      this.isPartner = result["data"]["results"][0]["is_partner"];
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.imgSrc = "";
      }
    });
  },
  methods: {
    cropImage() {
      this.imgSrc = "";
      let value = this.$refs.cropper.getCroppedCanvas();
      this.profile.avatar = value.toDataURL();
      value.toBlob((blob) => {
        const payload = new FormData();
        payload.append("avatar", blob);
        this.uploadAvatarProfile(payload);
      });
      localStorage.setItem("profile_avatar", this.profile.avatar);
    },
    async uploadAvatarProfile(payload) {
      await api.auth.editProfile(payload);
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        console.log("Ошибка");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Ошибка");
      }
    },
  },
};
</script>
