<template>
  <HeaderBlock :isHideSearch="false" />
  <section class="personal-section personal-none">
    <PersonalLeftBlock :hrefTo="'settings'" />
    <div class="block-right">
      <PersonalSettingsBlock :profile="profile" />
    </div>
  </section>
  <section class="mobile-personal-section">
    <a @click="$router.push({ name: 'personal_settings' })" class="profile">
      <span>{{ firstNameSymbol(profile) }}</span>
      {{ profile.firstName }} {{ profile.lastName }}
    </a>
    <a @click="$router.push({ name: 'personal_deliveries' })" class="delivery">
      <span class="title">
        <img src="@/assets/images/shop-geo.svg" />
        {{ city }}
      </span>
      <span v-if="delivery">{{ delivery }}</span>
      <span v-else>Укажите адрес доставки</span>
    </a>
    <a @click="$router.push({ name: 'personal_payments' })" class="payment">
      <span v-if="paymentCard" class="title">
        <PaymentLogoBlock :card="paymentCard" />
      </span>
      <span v-else>Добавить банковскую карту</span>
    </a>
    <a @click="$router.push({ name: 'personal_orders' })">Мои заказы</a>
    <a @click="$router.push({ name: 'personal_returns' })">
      Мои возвраты и замены
    </a>
    <a @click="$router.push({ name: 'personal_questionnaire' })">
      Подбор витаминов
    </a>
    <a @click="$router.push({ name: 'personal_loyalty_program' })">
      Программа лояльности
    </a>
    <a @click="$router.push({ name: 'personal_products' })">Мои товары</a>
    <a @click="$router.push({ name: 'personal_subscribers' })">Подписки</a>
    <a @click="$router.push({ name: 'personal_partner' })" v-if="isPartner">
      Партнерская программа - {{ partnerBalance }} руб.
    </a>
    <a @click="$router.push({ name: 'logout' })">Выйти из профиля</a>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import PersonalSettingsBlock from "@/components/PersonalSettingsBlock.vue";
import PaymentLogoBlock from "@/components/PaymentLogoBlock.vue";
import "@/assets/css/personal.css";
import api from "@/api";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    PersonalSettingsBlock,
    PaymentLogoBlock,
  },
  data() {
    return {
      city: "Москва",
      paymentCard: null,
      delivery: null,
      isPartner: false,
      partnerBalance: 0,
    };
  },
  async created() {
    document.title = "Настройка профиля";
    this.city = localStorage.getItem("city");
    try {
      const result = await api.profile.getUserPaymentCard();
      let cardLast = { updatedAt: 0 };
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        let card = {
          paymentName: item["payment_name"],
          panEnd: item["pan"].slice(-6),
          updatedAt: new Date(item["updated_at"]).getTime(),
          isDefault: item["is_default"],
        };
        if (card["isDefault"]) this.paymentCard = card;
        if (card["updatedAt"] > cardLast["updatedAt"]) cardLast = card;
      }
      if (!this.paymentCard && cardLast.updatedAt > 0) {
        this.paymentCard = cardLast;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getUserDelivery();
      let deliveryLast = { updatedAt: 0 };
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        let address = "";
        if (item["type"] === 1) {
          address = "ПВЗ: " + item["info_point"]["address"];
        } else {
          address = "Курьер: " + item["address_raw"];
        }
        let delivery = {
          address: address,
          updatedAt: new Date(item["updated_at"]).getTime(),
          isDefault: item["is_default"],
        };
        if (delivery["isDefault"]) this.delivery = delivery.address;
        if (delivery["updatedAt"] > deliveryLast["updatedAt"]) {
          deliveryLast = delivery;
        }
      }
      if (!this.delivery && deliveryLast.updatedAt > 0) {
        this.delivery = deliveryLast.address;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getPartnerBalance();
      this.isPartner = result["data"]["results"][0]["is_partner"];
      this.partnerBalance = result["data"]["results"][0]["partner_balance"];
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
